.page {
    @include drop-shadow;
    background-color: $white;
    border-radius: 1.5625rem;
    padding: 2.5rem 2rem;
    position: relative;

    &-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        
        &-sticky {
            margin-bottom: 3rem;
        }

        &-title {
            align-items: center;
            display: flex;
            justify-content: space-between;

            h1,
            h2.active {
                margin-right: 1.5625rem;
                padding-right: 1.5625rem;
                position: relative;

                &:after {
                    background-color: $blumine;
                    border-radius: 9999px;
                    content: '';
                    display: block;
                    height: 2rem;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 5px;
                }
            }

            &-filters {
                align-items: center;
                color: $nepal;
                display: flex;
                gap: 1rem;
                font-size: 0.875rem;
                justify-content: space-between;
            }
        }

        &-actions {
            align-items: center;
            display: flex;
            gap: 1rem;
            justify-content: space-between;
        }
    }
    &-table-container {
        overflow-y: auto;
        max-height: 33rem;
        margin: -2rem;
        overflow-x: hidden;
        margin-bottom: 1rem;
       
    }

    &-body {
        &.no-padding {
            margin: 0 -1rem;
        }
    }
}