* {
    box-sizing: border-box;
}

html,
body,
#root {
    @include montserrat;
    align-items: stretch;
    background-color: $mercury;
    color: $ebony-clay;
    display: flex;
    font-size: 16px;
    min-height: 100%;
    justify-content: stretch;
    margin: 0;
    padding: 0;
    width: 100%;
}

h1 {
    @include montserrat-bold;
    color: $blumine;
    font-size: 1.625rem;
    margin: 0;
    text-transform: uppercase;

    small {
        @include montserrat-regular;
        font-size: 1em;
    }
}

h2 {
    @include montserrat-bold;
    color: $blumine;
    font-size: 1.625rem;
    margin: 0 0 3.625rem;
    text-transform: uppercase;

    .light {
        @include montserrat-medium;
    }
}

h3 {
    @include montserrat-medium;
    color: $blumine;
    font-size: 1.625rem;
    margin: 0 0 3.625rem;
    text-transform: uppercase;

    small {
        @include montserrat-medium;
        font-size: 0.875rem;
        margin-left: 2rem;
    }
}

.tag-external {
    background-color: lighten($blue-lagoon, 10%);
    color:white;
    border-radius: 0.3125rem;
    padding: 0.15625rem 0.525em;
    text-transform: uppercase;
}

.tag-internal {
    background-color: lighten($blumine, 40%);
    color:white;
    border-radius: 0.3125rem;
    padding: 0.15625rem 0.525em;
    text-transform: uppercase;
}

.loader-container {
    align-items: center;
    background-color: rgba($nepal, 0.25);
    border-radius: 1.5625rem;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}