.left-nav {
    align-items: flex-start;
    background-color: $blumine;
    display: flex;
    flex-direction: column;
    flex: 0 0 5.625rem;
    max-height: 67.5rem;
    padding: 2rem 1.25rem;
    position: relative;
    z-index: 2;

    &.opened {
        flex: 0 0 11rem;

        .flyout {
            left: 11rem;
        }
    }

    &.closed {
        padding: 1.25rem 1rem;

        ul {
            align-items: center;

            li {

                a,
                a:link,
                a:hover,
                a:active,
                a:visited,
                button {
                    text-align: center;

                    span {
                        display: none;
                    }

                    svg {
                        margin-right: 0;
                    }

                    &.logout {
                        svg {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .logo {
        margin-bottom: 6.6875rem;
        max-width: 7.5625rem;
        width: 100%;
    }

    &-toggle {
        @include drop-shadow;
        align-items: center;
        align-self: flex-end;
        background-color: $white;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 2.5rem;
        justify-content: center;
        padding: 0;
        position: absolute;
        right: -1.5rem;
        top: 6rem;
        width: 2.5rem;
        z-index: 1;

        svg {
            @include transition;
            margin-left: -0.125rem;
        }

        &.closed {
            svg {
                margin-left: 0.125rem;
                transform: rotate(180deg);
            }
        }
    }

    ul {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 3.28125rem;

            &:last-child {
                margin-bottom: 0;
                margin-top: auto;
            }

            a,
            a:link,
            a:hover,
            a:active,
            a:visited,
            button {
                @include montserrat-semibold;
                @include transition;
                align-items: center;
                appearance: none;
                color: $nepal;
                cursor: pointer;
                display: flex;
                text-decoration: none;
                width: 100%;

                svg {
                    fill: $nepal;
                    margin-right: 0.75rem;
                    stroke: $nepal;
                }

                &.active {
                    color: $white;

                    svg {
                        fill: $tia-maria;
                        stroke: $tia-maria;
                    }

                    &:after {
                        background-color: $tia-maria;
                        border-radius: 9999px;
                        content: '';
                        display: block;
                        height: 2rem;
                        position: absolute;
                        right: -0.15625rem;
                        width: 0.3125rem;
                        z-index: 1;
                    }
                }

                &.logout {
                    display: flex;
                    width: 100%;

                    svg {
                        fill: $white;
                        margin-right: 1.075rem;
                        stroke: $white;
                    }
                }
            }

            button {
                padding: 0;
            }
        }
    }

    .flyout {
        background-color: $wild-sand;
        border-radius: 0 1.25rem 1.25rem 0;
        bottom: 0;
        left: 5.625rem;
        overflow: auto;
        padding: 12.75rem 1.8125rem 1.8125rem;
        position: absolute;
        top: 0;
        width: 22.75rem;
        z-index: 0;

        h2 {
            color: $ebony-clay;
        }

        button {
            margin-top: 1rem;
            text-align: center;
        }

        .form-field-wrapper {
            margin-top: 1rem;

            input {
                margin-top: 1rem;
            }

            select {
                margin-top: 1rem;
            }
        }

        .btn-add {
            @include montserrat-semibold;
            color: $blumine;
            font-size: 1.25rem;
        }

        &-buttons {
            display: flex;
            flex-direction: column;
        }
    }
}

.flyout-background {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}