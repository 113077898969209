button {
    appearance: none;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    text-align: left;

    &.form-select.dropdown-toggle.btn.btn-primary {
        background-color: $white;
        color: $gray-chateau;

        &.hasValue {
            color: $blumine;
        }
    }
}

a.button,
a.button:link,
a.button:hover,
a.button:active,
a.button:visited,
.button {
    @include montserrat-semibold;
    @include transition;
    font-size: 1rem;
    border-radius: 0.3125rem;
    cursor: pointer;
    padding: 0.8125em 0.625em;

    &.primary {
        background-color: $blumine;
        color: $white;

        &:active {
            background-color: $blumine;
        }

        &:hover {
            background-color: darken($blumine, 5%);
        }
    }

    &.secondary {
        background-color: $blue-lagoon;
        color: $white;

        &:active {
            background-color: $blue-lagoon;
        }

        &:hover {
            background-color: darken($blue-lagoon, 5%);
        }
    }

    &.tertiary {
        background-color: $tia-maria;
        border: 0 none;
        color: $white;

        &:active {
            background-color: $tia-maria;
        }

        &:hover {
            background-color: darken($tia-maria, 5%);
        }
        &.attachments-footer {
            margin-left: 2rem;
        }
    }

    &.notification {
        background-color: $red;
        border: 0 none;
        color: $white;
        text-decoration: none;
        padding: 0.5rem 1rem;
        &:active {
            background-color: $red;
        }

        &:hover {
            background-color: darken($red, 5%);
        }
    }
    &.note {
        background-color: $blumine;
        color: $white;
        padding: 0.1rem 0.1rem;

        &:active {
            background-color: $blumine;
        }

        &:hover {
            background-color: darken($blumine, 5%);
        }
    }

    &.outlined {
        background-color: $white;

        &.primary {
            border: 0.125rem solid $blumine;
            color: $blumine;
        }

        &.secondary {
            border: 0.125rem solid $tia-maria;
            color: $tia-maria;
        }

        &:active {
            background-color: $white;
        }

        &:hover {
            background-color: darken($white, 5%);
        }
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    &.small {
        font-size: 0.75rem;
        padding: 0.625em;
    }

    &.investigation-results {
        width:7rem;
        text-align:center;
    }

    &.investigation-results-save {
        width:7rem;
        text-align:center;
        color: $white;
        background-color: $tia-maria;
        border: 0.125rem solid $tia-maria;
        &:active {
            background-color: $tia-maria;
        }

        &:hover {
            background-color: darken($tia-maria, 5%);
        }
        
    }
}