/* colors */
$alto: #D8D8D8;
$athens-gray: #F3F5F8;
$black: #000000;
$blumine: #1D4681;
$blue-lagoon: #00818C;
$boulder: #777777;
$double-pearl-lusta: #FCFAD5;
$dusty-gray: #979797;
$ebony-clay: #272D3B;
$gallery: #EAEAEA;
$gray-chateau: #9A9FA8;
$mercury: #E4E4E4;
$nepal: #8EA3C0;
$silver-chalice: #A9A9A9;
$tia-maria: #D34013;
$wax-flower: #FFC5B4;
$white: #ffffff;
$wild-sand: #F4F4F4;
$red: #FF0000;

/* fonts */
@mixin montserrat {
    font-family: 'Montserrat', sans-serif;
}

@mixin montserrat-regular {
    font-weight: 400;
}

@mixin montserrat-medium {
    font-weight: 500;
}

@mixin montserrat-semibold {
    font-weight: 600;
}

@mixin montserrat-bold {
    font-weight: 700;
}

/* animations */
@mixin transition {
    transition: all 250ms ease-in-out;
}

/* misc */
@mixin drop-shadow {
    box-shadow: 0 0.1875rem 0.375rem rgba($black, 0.2);
}