.rdt_Table {
    .rdt_TableHead {
        .rdt_TableHeadRow {
            padding-left: calc(2rem - 16px);
            padding-right: calc(2rem - 16px);

            .rdt_TableCol {
                @include montserrat-bold;
                color: $ebony-clay;
                font-size: 0.875rem;
                text-transform: uppercase;

                .rdt_TableCol_Sortable {

                    &:hover {
                        opacity: 1;
                    }

                    .gnetL,
                    .exsEIO,
                    .bBpdsi,
                    .feGxUP {
                        background-position: center;
                        background-repeat: no-repeat;
                        margin-left: 0.25rem;
                        opacity: 1;
                        text-indent: -9999px;
                        transform: none;
                        width: 0.5rem;
                    }

                    // default
                    .gnetL,
                    .exsEIO {
                        background-image: url('../assets/ico-sort-default.svg');
                    }

                    // up
                    .bBpdsi {
                        background-image: url('../assets/ico-sort-up.svg');
                    }

                    // down
                    .feGxUP {
                        background-image: url('../assets/ico-sort-down.svg');
                    }
                }
            }
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            border-color: $alto;
            font-size: 0.875rem;
            padding-left: calc(2rem - 16px);
            padding-right: calc(2rem - 16px);
        }
    }

    a {
        color: inherit;
    }

    input[type=checkbox] {
        appearance: none;
        background-color: $white;
        border: 0.0625rem solid $blumine;
        border-radius: 100%;
        height: 1.25rem;
        width: 1.25rem;

        &:checked {
            background: $blumine url('../assets/ico-check.svg') center no-repeat;
            background-size: 70%;
        }
    }
}

.rdt_Pagination {
    padding-top: 1rem;

    &.gKbhqU {
        flex-direction: column;
        justify-content: center;

        span {
            color: $nepal;
        }

        div {
            display: flex;
            gap: 1rem;
            padding-top: 1rem;

            #pagination-first-page,
            #pagination-previous-page,
            #pagination-next-page,
            #pagination-last-page {
                @include drop-shadow;
                @include transition;
                background-color: $blumine;
                border-radius: 100%;
                cursor: pointer;
                height: 2.5rem;
                width: 2.5rem;

                &:hover {
                    background-color: darken($blumine, 5%);
                }

                svg {
                    fill: $white;
                }
            }
        }
    }
}

.custom-table {
    &-header {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    >div>div {
        table-layout: fixed;
    }

    &-link {
        cursor: pointer;
        text-decoration: underline;
    }
}