form {

    .form-header {
        color: $nepal;
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .form-footer {
        color: $nepal;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .locate-address-header {
        color: black;
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .locate-address {
        background-color: $double-pearl-lusta;
        padding: 1rem 1rem 0;
        border-radius: 1rem;
        margin-bottom: 3rem;
        @include drop-shadow;
    }

    .form-wrapper {
        margin: 0 auto;
        width: 100%;

        &.investigation-type {
            max-width: 24rem;
        }

        &.general-information,
        &.client-information
        &.additional-information {
            max-width: 64.625rem;
        }

        &.client-information,
        &.general-information {
            color: $blumine;

            .content {
                ol {
                    font-weight: bold;

                    span {
                        font-weight: normal;
                    }
                }
            }
        }
        &.client-information {
            text-align: center;
            border-top: 0.0625rem solid #979797;
            padding-top: 2rem;
        }


        &.asset-locate-request {
            max-width: 67.5rem;

            .form-check input[type=checkbox] {
                margin-right: 0.1875rem;
            }
        }
    }

    label {
        @include montserrat-bold;
        align-items: center;
        font-size: 0.875rem;

        small {
            @include montserrat-regular;
            margin-left: 0.25rem;
            margin-right: auto;
        }
    }

    button {

        &.form-previous,
        &.form-submit {
            @include montserrat-semibold;
            @include transition;
            align-items: center;
            background-color: $tia-maria;
            border-radius: 0.3125rem;
            color: $white;
            display: flex;
            gap: 1.5625rem;
            justify-content: center;
            padding: 0.75rem 1.5625rem;

            &:hover {
                background-color: darken($tia-maria, 5%);
            }

            &:disabled {
                cursor: default;
                opacity: 0.5;
            }
        }

        &.form-previous {
            @include montserrat-semibold;
            @include transition;
            background-color: $white;
            border-radius: 0.3125rem;
            border: 0.125rem solid $tia-maria;
            color: $tia-maria;

            &:hover {
                background-color: darken($white, 5%);
            }
        }
    }

    .radio-group {
        list-style-type: none;
        margin: 0;
        padding: 0;

        label {
            @include montserrat-regular;
            font-size: 1rem;
        }

        li.form-check {
            margin-bottom: 0.5625rem;

            &:last-of-type {
                margin-bottom: 0.5625rem;
            }
        }

        .accordion {
            margin-bottom: 0.5625rem;

            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        padding: 0;

                        &:before {
                            height: 1.8125rem;
                            margin-right: 1rem;
                            text-align: center;
                            width: 1.8125rem;
                        }
                    }
                }

                .accordion-body {
                    padding: 0.5625rem 0 0;

                    .form-check:last-of-type {
                        margin-bottom: 0.5625rem;
                    }
                }
            }
        }
    }

    .form-select {
        border: 0.125rem solid $blumine;
        font-size: 0.875rem;
        overflow: hidden;
        position: relative;
        padding-right: 1rem;
        text-align: left;

        &.hasValue {
            color: $blumine;
        }

        &:focus,
        &:active {
            border: 0.125rem solid $blumine;
            box-shadow: none;
        }

        &:after {
            background: $white url('../assets/ico-arrow-down.svg') center no-repeat;
            border: 0 none;
            content: "";
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 2.25rem;
            z-index: 0;
        }
    }

    .dropdown {
        .dropdown-menu {
            width: 100%;
        }
    }

    .form-row {
        align-items: flex-start;
        border-bottom: 0.0625rem solid $dusty-gray;
        margin-bottom: 2rem;
        padding-bottom: 2rem;

        &:not(.single-col) {
            column-gap: 2rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 1rem;
        }

        &.two-col {
            grid-template-columns: 1fr 1fr;
        }

        &.three-col {
            grid-template-columns: 1fr 1fr 2fr;

            &-reversed {
                grid-template-columns: 2.1365fr 1fr 1fr;
            }

            &-equal {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &.six-col {
            grid-template-columns: repeat(6, 1fr);
        }

        &.no-border,
        &:last-of-type {
            border: 0 none;
        }

        &.no-spacing {
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
    }

    .form-field-wrapper {
        display: flex;
        flex-direction: column;

        label {
            display: flex;
            justify-content: space-between;

            .required {
                color: $tia-maria;
            }

            &.left-justified {
                justify-content: flex-start;

                .required {
                    margin-left: 1rem;
                }
            }
        }
    }

    .form-error {
        @include montserrat-semibold;
        color: $tia-maria;
        font-size: 0.875rem;
    }

    input,
    select,
    textarea,
    .rbt-input-main {
        appearance: none;
        border: 0.0625rem solid $silver-chalice;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        width: 100%;

        &:active,
        &:focus {
            border: 0.0625rem solid $silver-chalice;
            outline: none;
        }

        &:autofill {
            box-shadow: 0 0 0 50px white inset;

            &:hover,
            &:focus,
            &:active {
                box-shadow: 0 0 0 50px white inset;
            }
        }

        &.placeholder {
            background-color: transparent !important;
            color: $gray-chateau;
            cursor: default;
            font-size: 0.875rem;
            opacity: 1;
            padding-bottom: 0.34375rem;
            padding-top: 0.34375rem;
        }

        &:placeholder {
            color: $gray-chateau;
            font-size: 0.875rem;
        }
    }

    .rbt-input-main,
    select {
        background: $white url('../assets/ico-caret-bottom.svg') no-repeat center right 0.5rem;

        &:autofill {
            background: url('../assets/ico-caret-bottom.svg') no-repeat center right 0.5rem;

            &:hover,
            &:focus,
            &:active {
                background: url('../assets/ico-caret-bottom.svg') no-repeat center right 0.5rem;
            }
        }
    }

    .buttons {
        &-centered {
            margin-bottom: 5.3125rem;
            text-align: center;
        }

        &-right {
            margin-bottom: 5.3125rem;
            text-align: right;
        }
    }

    .repeater {
        border-bottom: 0.0625rem solid $silver-chalice;
        margin-bottom: 2rem;
    }
}

.form-check {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    padding: 0;

    &-inline {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        &.left-justified {
            gap: 4.8125rem;
            justify-content: flex-start;

            input[type=radio] {
                margin-right: 0.25rem;
            }
        }

        &.single-col {
            justify-content: space-between;
            margin: 0;

            input[type=radio] {
                margin-right: 0.25rem;
            }
        }
    }

    &-list {
        flex-direction: column;

        .form-check {
            align-items: center;
            margin-bottom: 1rem;
        }
    }

    label {
        @include montserrat-regular;
        cursor: pointer;

        ul & {
            @include montserrat-bold;
        }

        .investigation-type & {
            @include montserrat-regular;
        }
    }

    input[type=radio],
    input[type=checkbox] {
        align-items: center;
        appearance: none;
        background-color: transparent;
        border: 0.0625rem solid $blumine;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        float: none;
        flex-grow: 0;
        flex-shrink: 0;
        height: 1rem;
        justify-content: center;
        margin: 0 1rem 0 0;
        padding: 0;
        width: 1rem;

        ul & {
            border: 0.1875rem solid $blumine;
            height: 1.8125rem;
            width: 1.8125rem;
        }

        &[type=radio] {
            &:checked {
                &:after {
                    background-color: $blumine;
                    border-radius: 100%;
                    content: "";
                    display: block;
                    height: 0.5rem;
                    width: 0.5rem;

                    ul & {
                        height: 1rem;
                        width: 1rem;
                    }
                }
            }
        }

        &[type=checkbox] {
            border-radius: 100%;

            &:checked {
                background: $blumine url('../assets/ico-check.svg') center no-repeat;
                background-size: 70%;
            }
        }
    }
}

.form-footer {
    align-items: center;
    display: flex;

    &-middle {
        flex: 0 0 48.375rem;
        padding: 0 4.6875rem;
    }

    &-left,
    &-right {
        display: flex;
        flex: 1 1 100%;
    }

    &-left {
        justify-content: flex-end;
    }

    &-right {
        gap: 1.5rem;
        justify-content: flex-start;
    }

    &-sub {
        align-items: center;
        display: flex;
        gap: 1.5625rem;
        justify-content: center;
    }
}

.react-datepicker__input-container {
    input {
        background: url('../assets/ico-calendar.svg') center right 0.5rem no-repeat;
    }
}

.investigation-results-container-left {
    margin-left:3rem;
    margin-right:auto;
}

.investigation-results-container-right {
    margin-left:auto;
    margin-right:3rem;
}