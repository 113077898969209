.file-input {
    display: flex;
    justify-content: space-between;

    &-left {
        flex: 0 1 19%;

        button {
            text-align: center;
            width: 100%;
        }

        small {
            display: block;
            margin-top: 1.25rem;
        }
    }

    &-right {
        flex: 0 1 74%;
    }

    &-drag-drop {
        @include montserrat-bold;
        align-items: center;
        border: 0.0625rem solid $silver-chalice;
        border-radius: 0.25rem;
        color: $silver-chalice;
        display: flex;
        height: 7.875rem;
        justify-content: center;
        margin-bottom: 4rem;

        img {
            margin-right: 1.5625rem;
        }
    }

    &-file-list {
        @include montserrat-bold;
        font-size: 0.875rem;
        width: 20rem;

        &-remove {
            cursor: pointer;
        }

        strong {
            display: block;
            margin-bottom: 1.4375rem;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                align-items: center;
                color: $blue-lagoon;
                display: flex;
                gap: 3.3125rem;
                justify-content: space-between;
                margin-bottom: 1.4375rem;
            }
        }

        .ready-to-upload {
            margin-bottom: 2rem;

            &-button {
                text-align: right;
            }
        }
    }
}