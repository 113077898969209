.breadcrumbs {
    color: $boulder;
    display: flex;
    gap: 1rem;
    font-size: 0.875rem;
    margin: 1.375rem 4.5rem 0;

    a {
        color: $boulder;
        text-decoration: none;

        &:after {
            border: solid $boulder;
            border-width: 0 1px 1px 0;
            content: " ";
            display: inline-block;
            margin-left: 1rem;
            padding: 3px;
            transform: rotate(-45deg);
        }

        &:first-child {
            font-weight: bold;
        }

        &:last-child {
            &:after {
                border: 0 none;
                margin-left: 0;
            }
        }
    }

    >span {
        &:after {
            border: solid $boulder;
            border-width: 0 1px 1px 0;
            content: " ";
            display: inline-block;
            margin-left: 1rem;
            padding: 3px;
            transform: rotate(-45deg);
        }

        &:first-child {
            font-weight: bold;
        }

        &:last-child {
            &:after {
                border: 0 none;
                margin-left: 0;
            }
        }
    }
}