.header {
    @include drop-shadow;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;

    &-logo {
        @include montserrat-medium;
        align-items: center;
        color: $blumine;
        display: flex;
        font-size: 1.0625rem;

        img {
            margin-left: 0.975rem;

            .layout.intake & {
                height: 2rem;
            }
        }
    }

    &-username {
        @include montserrat-semibold;
        align-items: center;
        display: flex;
        line-height: 1.1875em;
    }
}

.notification {
    @include montserrat-semibold;
    
    padding-right: 1.5rem;
}