.layout {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;

    &-content {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        width: 100%;

        &-outlet {
            flex: 1 1 100%;
            padding: 1rem 2.5rem 2.5rem;

            &.noBreadcrumbs {
                padding-top: 3.5rem;
            }
        }

        .two-column-pages {
            display: flex;
            gap: 1.5625rem;
            justify-content: space-between;

            .page {
                flex: 1 1 50%;
            }
        }
    }

    &.intake {
        .left-rail {
            align-items: center;
            background-color: $blumine;
            border-bottom-right-radius: 1.5625rem;
            display: flex;
            height: 10.5rem;
            justify-content: center;
            width: 11rem;
        }

        .layout-content-outlet {
            padding: 0 2.5rem 2.5rem 0;

            >h1 {
                align-items: center;
                display: flex;
                height: 6.5rem;
                padding: 0 2rem;
            }
        }

        .page:not(.confirmation) {
            .asset-locate-type {
                padding-bottom: 19.1875rem;
            }
        }
    }

    .portal,
    .success {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .left {
            align-items: center;
            display: flex;
            gap: 1rem;

            h2,
            h3 {
                margin: 0;
            }
        }

        .right {
            align-items: center;
            display: flex;
            gap: 1rem;
        }
    }

    .success {
        margin-bottom: 2rem;
    }
}