/**
 * Dropdowns
 */
.dropdown {
    .button {
        align-items: center;
        border: 0 none;
        display: flex;
        justify-content: center;

        &.dropdown-toggle::after {
            content: none;
        }

        &.filter,
        &.new,
        &.action {
            position: relative;

            svg {
                @include transition;
                fill: $white;
                margin-left: 1rem;
                transform: rotate(-90deg);
            }
        }

        &.filter {
            padding-left: 2rem;
            padding-right: 2rem;

            .dropdown {
                background-color: lighten($blumine, 65%);
                border-color: $blumine;

                ul li {
                    &:hover {
                        background-color: rgba($blumine, 0.25);
                    }
                }
            }
        }

        &.new {
            padding-left: 1.3125rem;
            padding-right: 1.3125rem;

            .dropdown {
                background-color: lighten($blue-lagoon, 65%);
                border-color: $blue-lagoon;

                ul li {
                    &:hover {
                        background-color: rgba($blue-lagoon, 0.25);
                    }
                }
            }
        }

        &.action {
            padding-left: 4.0625rem;
            padding-right: 4.0625rem;

            .dropdown {
                background-color: lighten($tia-maria, 50%);
                border-color: $tia-maria;

                ul li {
                    &:hover {
                        background-color: rgba($tia-maria, 0.25);
                    }
                }
            }
        }
    }

    &.show {
        .button {

            &.filter,
            &.action {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .dropdown-menu {
        @include drop-shadow;
        border: 0.125rem solid;
        border-radius: 0.25rem;
        color: $ebony-clay;
        padding: 0.75rem 0.6875rem;

        .dropdown-item {
            @include montserrat-regular;
            @include transition;
            font-size: 1rem;
            padding: 0.5rem 0.75rem;
        }

        .accordion {
            white-space: nowrap;
        }
    }

    .dropdown-header {
        &.center {
            align-items: center;
            display: flex;
            gap: 1rem;
            justify-content: center;

            .button {
                padding: 0.25em 0.625em;
                width: 100%;
            }
        }
    }

    &.filter {
        .dropdown-menu {
            background-color: lighten($blumine, 65%);
            border-color: $blumine;

            .accordion-button {
                &:hover {
                    background-color: rgba($blumine, 0.25);
                }
            }
        }
    }

    &.new {
        .dropdown-menu {
            background-color: lighten($blue-lagoon, 65%);
            border-color: $blue-lagoon;

            .dropdown-item {
                &:hover {
                    background-color: rgba($blue-lagoon, 0.25);
                }
            }
        }
    }

    &.action {
        .dropdown-menu {
            background-color: lighten($tia-maria, 50%);
            border-color: $tia-maria;

            .dropdown-item {
                &:hover {
                    background-color: rgba($tia-maria, 0.25);
                }
            }
        }
    }
}

/**
 * Modal
 */
.modal {
    .modal-dialog {
        max-width: 38rem;
    }

    .modal-content {
        border-radius: 1.5625rem;
        position: relative;

        .modal-header,
        .modal-body,
        .modal-footer {
            padding-left: 17%;
            padding-right: 17%;

            &.no-padding {
                padding-left: 5%;
                padding-right: 5%;
            }
        }

        .modal-header {
            border: 0 none;

            .modal-title {
                @include montserrat-bold;
                color: $blumine;
                font-size: 1.625rem;
            }

            .btn-close {
                background: url('../assets/ico-close.svg') center no-repeat;
                margin: 0;
                opacity: 1;
                position: absolute;
                right: 1rem;
                top: 1.5rem;
            }
        }

        .modal-footer {
            border: 0 none;
            justify-content: center;
        }
    }
}


/**
 * According
 */
.accordion {
    .accordion-item {
        background-color: transparent;
        border: 0 none;

        .page-header-title-filters {
            margin-right: auto;
        }

        .accordion-header {
            .accordion-button {
                align-items: center;
                background-color: transparent;
                box-shadow: none;
                color: $ebony-clay;
                padding-bottom: 0.25rem;
                padding-top: 0.25rem;

                &:before {
                    @include montserrat-semibold;
                    content: "-";
                    font-size: 1.5rem;
                    margin-right: 2rem;
                }

                &.collapsed {
                    &:before {
                        content: "+";
                    }
                }

                &:after {
                    content: none;
                }
            }
        }

        .accordion-body {
            .form-check {
                margin-bottom: 0.5rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}


/**
 * Progress Bar
 */
.progress {
    background-color: $gallery;
    height: 0.5rem;

    &-bar {
        background-color: $tia-maria;
        border-radius: 9999px;
    }
}